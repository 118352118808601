



































import { Component, Vue, Watch } from 'vue-property-decorator';
import { IOrderBumpItem } from '@/sparkmembers/views/ProductSetup/components/OrderBumpConfig/types';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';

@Component({
  components: {
    OrderBumpListItem: () => import('./OrderBumpListItem.vue'),
  },
})
export default class OrderBumpList extends Vue {
  orderBumpList: Array<IOrderBumpItem> = [];
  currentPage: Number = 1;
  totalCount: Number = 0;

  mounted() {
    this.fetchOrderBumpList();
  }

  async fetchOrderBumpList(itemsPerPage = 3) {
    try {
      const coreProductId = this.$route.params.id;

      const { data, headers } = await SparkEcommerceApiClient.orderBump.all(
        coreProductId,
        this.currentPage,
        itemsPerPage
      );

      this.orderBumpList = data;
      this.totalCount = headers['total-count'];
    } catch {
      this.orderBumpList = [];
    }
  }

  @Watch('currentPage')
  onCurrentPageChange() {
    this.fetchOrderBumpList();
  }
}
